import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
import DrawSVGPlugin from '../lib/DrawSVGPlugin.min';
import SplitText from '../lib/SplitText.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin, SplitText);

import lity from 'lity';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.fromTo(_elmc,
			{ opacity:0, y:0 },
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.inOut",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 60;
	}else{
		tar_offset = 60;
	}
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


function hoverBox(){
	const hovbox_wrap_trigger = ScrollTrigger.create({
		trigger: '#hovbox_wrap',
		start: "top top",
		end: 'top bottom',
		endTrigger: '#contact',
		pin: true,  
	});

	const hvFunc = (margin) => {
		const hv = gsap.timeline({
			defaults: {
				duration:0.3,
				ease:'power2',
				stagger: 0.1,
			},
			repeat:-1,
			repeatDelay:0,
			yoyo:true
		})
		.fromTo('.hovbox', {xPercent:() => {return margin}}, {xPercent:30, delay:3});

		const hvboxEnter = (target) => {
			hv.pause();
			gsap.to(target, {xPercent:0,duration:0.3,ease:'power2'});
		}
		const hvboxLeave = (target) => {
			hv.play();
			gsap.to(target, {xPercent:() => {return margin},duration:0.3,ease:'power2'});
		}
		gsap.utils.toArray('.hovbox').forEach((box, i) => {
			box.addEventListener('mouseenter', (e) => hvboxEnter(e.target));
			box.addEventListener('mouseleave', (e) => hvboxLeave(e.target));
		});
	}

	let mm = gsap.matchMedia();
	let margin;
	mm.add("(min-width: 768px)", () => {
		margin = 85;
		hvFunc(margin);
	});
	mm.add("(max-width: 767px)", () => {
		margin = 95;
		hvFunc(margin);
	});
}

function wwcFunc(){
	const wwc = gsap.timeline({
		defaults: {
			duration: 0.4,
		},
		scrollTrigger: {
			trigger: '#wwc_image',
			start: "top bottom-=10%",
			once: true,
			onRefresh: self => self.progress === 1 && self.animation.progress(1)
		}
	})
	.fromTo('.wwc_svg .cir path', {drawSVG:'0%'}, {drawSVG:'100%'})
	.fromTo('.wwc_svg .line', {drawSVG:'0%'}, {drawSVG:'100%'})
	.fromTo('.wwc_svg .arw', {fill:'none'}, {fill:'#000'})
	.fromTo('.wwc_svg .cir path', {fill:'rgba(255,255,255,0)'}, {fill:'rgba(255,255,255,1)',duration:0.01}, '<')
	.fromTo('.wwc_svg .txt', {autoAlpha:0}, {autoAlpha:1, duration:1.2, ease:'power2', stagger:0.1})
}


function bang(){
	const body = document.body;
	const bang1 = document.getElementById('bang1');
	const bangAction = () => {
		body.classList.add('bang');
		setTimeout(() => {
			body.classList.remove('bang');
		}, 2000);
	}
	bang1.addEventListener('click', function(){
		bangAction();
	});
}


function slidetxt(){
	gsap.utils.toArray('.slidetxt').forEach(el => {
		gsap.fromTo(el,
			{ xPercent:100 },
			{ xPercent:0, 
				duration: 0.8,
				ease: "linear",
				scrollTrigger: {
					trigger: el,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});
}


function wwtFunc(){
	let mm = gsap.matchMedia();
	const cirlemove = gsap.timeline({
		defaults: {
			duration:0.3,
			ease:'power2',
		},
		paused: true,
		repeat:-1,
		repeatDelay:1,
	})
	.fromTo('#wwt_svg .cirpath', {fill:'#BEBEBE'}, {fill:'#ffffff', stagger:0.6})
	.to('#wwt_svg .cirpath', {fill:'#BEBEBE', stagger:0.6}, '<=+0.6');

	const wwt = gsap.timeline({
		defaults: {
			duration: 0.2,
		},
		scrollTrigger: {
			trigger: '#wwt_image',
			start: "top bottom-=10%",
			once: true,
			onRefresh: self => self.progress === 1 && self.animation.progress(1)
		}
	})
	.fromTo('#wwt_image .cirpath', {drawSVG:'0%'}, {drawSVG:'100%', stagger:0.1})
	.fromTo('#wwt_image .cirtxt', {autoAlpha:0}, {autoAlpha:1, stagger:0.1}, '<=+0.6')
	.fromTo('#wwt_image .l0', {drawSVG:'0%'}, {drawSVG:'100%'})
	.fromTo('#wwt_image .line', {drawSVG:'0%'}, {drawSVG:'100%', stagger:0.05})
	.fromTo('#wwt_image .txt', {autoAlpha:0}, {autoAlpha:1, stagger:0.05}, '<=+0.6')
	.add(() => {
		mm.add("(min-width: 768px)", () => {
			const cirlemove = gsap.timeline({
				defaults: {
					duration:0.3,
					ease:'power2',
				},
				repeat:-1,
				repeatDelay:1,
			})
			.fromTo('#wwt_svg .cirpath', {fill:'#BEBEBE'}, {fill:'#ffffff',duration:0.01,stagger:0.6})
			.to('#wwt_svg .cirpath', {fill:'#BEBEBE',duration:0.01,stagger:0.6}, '<=+0.6');
		});
		mm.add("(max-width: 767px)", () => {
			const cirlemove = gsap.timeline({
				defaults: {
					duration:0.3,
					ease:'power2',
				},
				repeat:-1,
				repeatDelay:1,
			})
			.fromTo('#wwt_svg_sp .cirpath', {fill:'#BEBEBE'}, {fill:'#ffffff',duration:0.01,stagger:0.6})
			.to('#wwt_svg_sp .cirpath', {fill:'#BEBEBE',duration:0.01,stagger:0.6}, '<=+0.6');
		});
	
	});
}


function wwdFunc(){
	let zindex = 10;
	const items = gsap.utils.toArray('.wwd_item');
	const cardnum = gsap.utils.toArray('.cardnum');
	const cards = gsap.utils.toArray('.wwd_card');
	const cardclose = gsap.utils.toArray('.cardclose');
	items.forEach((item, i) => {
		item.addEventListener('click', (e) => {
			zindex++;
			items.forEach(itm => itm.classList.remove('active'));
			e.currentTarget.classList.add('active');
			cards[i].classList.add('active');
			cards[i].style.zIndex = zindex;
		});
	});
	cardnum.forEach((num, i) => {
		const num_p = num.querySelectorAll('p');
		num_p.forEach((p, i) => {
			p.addEventListener('click', (e) => {
				zindex++;
				items.forEach(itm => itm.classList.remove('active'));
				items[i].classList.add('active');
				cards[i].classList.add('active');
				cards[i].style.zIndex = zindex;
			});
		});
	});
	cards.forEach((card, i) => {
		card.addEventListener('click', (e) => {
			zindex++;
			items.forEach(itm => itm.classList.remove('active'));
			items[i].classList.add('active');
			e.currentTarget.style.zIndex = zindex;
		}, true);
	});
	cardclose.forEach((el, i) => {
		el.addEventListener('click', (e) => {
			items.forEach(itm => itm.classList.remove('active'));
			e.currentTarget.parentElement.classList.remove('active');
		});
	});

	cards.forEach((card, i) => {
		card.onpointermove = function(event){
			if(event.buttons){
				this.style.left     = this.offsetLeft + event.movementX + 'px'
				this.style.top      = this.offsetTop  + event.movementY + 'px'
				this.style.position = 'absolute'
				this.draggable      = false
				this.setPointerCapture(event.pointerId)
			}
		}
	});
}


function caseList(){
	gsap.utils.toArray('.case').forEach(el => {
		const txt = el.querySelectorAll('span');
		gsap.fromTo(txt,
			{ opacity:0 },
			{ opacity:1, 
				duration: 1.2,
				delay: 0.2,
				ease: "power2",
				scrollTrigger: {
					trigger: el,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});
}


function formFunc() {
	const formclose = () => {
		const form = document.getElementById('contact_form');
		const formclose = document.getElementById('formclose');
		formclose.addEventListener('click', function(){
			form.classList.add('hide');
			setTimeout(() => {
				form.classList.remove('hide');
			}, 2000);
		});
	}
	formclose();

	const formsentFunc = () => {
		var wpcf7Elm = document.querySelector( '.wpcf7' );
		wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
			document.getElementById('thanks').classList.add('active');
		}, false );
	}
	formsentFunc();
}

const contact_Cf7 = () => {
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
			console.log('cf7');
		});
	}
}



function company_img(){
	const cirlemove = (tar) => {
		const tar_cir = tar.querySelectorAll('.cir');
		const cirlemove = gsap.timeline({
			defaults: {
				duration:0.3,
				ease:'power2',
			},
			repeat:-1,
			repeatDelay:0.6,
		})
		.fromTo(tar_cir, {fill:'#BEBEBE'}, {fill:'#ffffff',duration:0.01,stagger:0.6})
		.to(tar_cir, {fill:'#BEBEBE',duration:0.01,stagger:0.6}, '<=+0.6');
	}

	let mm = gsap.matchMedia();
	let target;
	mm.add("(min-width: 768px)", () => {
		target = document.getElementById('company_svg');
		cirlemove(target);
	});
	mm.add("(max-width: 767px)", () => {
		target = document.getElementById('company_svg_sp');
		cirlemove(target);
	});
	
}


function profile(){
	const detailHeight = document.getElementById('detail_dl').offsetHeight;
	const profile = document.getElementById('profile');
	profile.style.height = `${detailHeight}px`;

	window.addEventListener('resize', function(){
		const newDetailHeight = document.getElementById('detail_dl').offsetHeight;
		profile.style.height = `${newDetailHeight}px`;
	});
}

function profile_btn(){
	const prof = document.getElementById('profile');
	const profclose = document.getElementById('profclose');
	const profopen = document.getElementById('profopen');
	profclose.addEventListener('click', function(){
		prof.classList.add('hide');
	});
	profopen.addEventListener('click', function(){
		prof.classList.remove('hide');
	});
}


function splitFadeText() {
	gsap.utils.toArray('.splitblc').forEach(el => {
		const txt = el.querySelectorAll('.splittxt');
		const text = new SplitText (txt,
		{type: 'chars',})
			gsap.from (text.chars, {
			stagger: 0.3,
			opacity: 0.2,
			scrollTrigger: {
				pinType: 'fixed',
				trigger: el,
				start: 'top center',
				end: 'bottom center',
				scrub: 1,
			}
		});
	});
}


function progress() {
	const progress = document.getElementById('progress');
	const per = document.getElementById('per');
	ScrollTrigger.create({
		trigger: '#contents',
		start: 'top bottom',
		end: 'bottom bottom',
		onUpdate: self => {
			const progress = Math.round(self.progress*100);
			per.innerHTML = progress;
		},
		onLeave: self => {
			progress.classList.add('fin');
		},
		onEnterBack: self => {
			progress.classList.remove('fin');
		}
	});
}


function lityFunc() {
	gsap.utils.toArray('#conte_blc a').forEach(target => {
		target.setAttribute('data-lity', 'data-lity');
		// const src = target.getAttribute('href');
		// target.addEventListener('click', function(){

		// 	lity('//player.vimeo.com/video/'+vimeoid);
		// });
	});
}



var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		pageScroll();
		elFunc();
		slidetxt();
		splitFadeText();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		wwcFunc();
		hoverBox();
		bang();
		wwtFunc();
		wwdFunc();
		caseList();
		formFunc();
	},
	case_archiveFunc: function(){
		caseList();
	},
	companyFunc: function(){
		company_img();
		profile();
		profile_btn();
	},
	singleFunc: function(){
		slidetxt();
		progress();
		lityFunc();
	}
};


function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrl onevh navwh');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			if( document.getElementById('top') ){
				contact_Cf7();
			}
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('case_archive') ){
		PageEvents.case_archiveFunc();
	}
	if( document.getElementById('company') ){
		PageEvents.companyFunc();
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}